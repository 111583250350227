#container-override {
	width: 100%;
    color: rgb(20, 20, 20);
    margin-bottom: 150px;
}

.content-override {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 20px;
	font-weight: 500;
	overflow-wrap: normal;
    margin-top: 50px;
}

.blogs {
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
	width: 100%;
    transition: all 0.2s ease;
}

.markdown {
    padding: 20px;
    padding-left: 10vw;
    padding-right: 10vw;
}