.navbar {
	width: 100%;
	height: 65px;
	font-size: calc(12px + 0.8vw);
	font-weight: 500;
	color: rgb(223, 223, 223);
	background-color: rgb(10, 10, 10);
	margin-bottom: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;
	position: fixed;
	z-index: 1000;
    transition: all 0.2s ease;
}

.navbar-link {
    color: rgb(223, 223, 223);
    text-decoration: none;
    transition: color 0.2s ease;
}

.navbar-link:hover {
    color: rgb(170, 170, 170);
}

@media (min-width: 1000px) {
	.navbar {
	  font-size: 18px;
	}
}