#container-override {
	width: 100%;
    color: rgb(20, 20, 20);
    margin-bottom: 150px;
}

.content-override {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 20px;
	font-weight: 500;
	overflow-wrap: normal;
    margin-top: 50px;
}

.experience-content-override {
    margin: 0;
    align-items: center;
}

.blogContainer {
    background-color: rgb(241, 241, 241);
    font-weight: 500;
    text-align: center;
    width: 100%;
    overflow-y: hidden;
}

.blogContent {
    height: 100vh;
    color: rgb(20, 20, 20);
    display: flex;
    padding-top: 65px;
    justify-content: center;
    transition: all 0.2s ease;
}

.blog {
    width: 30vw;
    border: solid 3px rgb(20, 20, 20);
    border-radius: 20px;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    margin: 20px;
    margin-bottom: 25px;
    transition: background-color 0.2s ease;
}

.blog:hover {
    background-color: rgb(218, 218, 218);
}

.blogs {
    padding: 20px;
    margin: 20px;
    display: flex;
    flex-direction: column;
	width: 100%;
    transition: all 0.2s ease;
}

.blog-title {
    margin: 20px;
}

.blog-desc {
    margin: 20px;
    color: rgb(149, 149, 149);
    font-weight: 500;
    font-size: 20px;
}

@media (max-width: 830px) {
    .blog {
        width: 60vw;
    }
}