.header {
	font-size: 40px;
	font-weight: 600;
	margin-right: 30vw;
	margin-bottom: 20px;
}

.content {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	font-size: 20px;
	font-weight: 500;
	gap: 2vw;
	overflow-wrap: normal;
}

#container {
	width: 80%;
}

#portrait {
	width: 250px;
	height: 300px;
	border-radius: 20px;
	border: solid 3px rgb(20, 20, 20);
}

#portrait-container {
	width: 250px;
	margin: 30px;
}

.download {
    text-decoration: none;
    transition: all 0.2s ease-out;
	color: rgb(88,80,236);
}

.download:hover {
	color:rgb(149, 120, 255);
}

.about-section {
    margin: 0;
    margin-bottom: 30px;
    flex-direction: column;
    align-items: center;
}

#text {
    display: flex;
    flex-direction: column;
	margin-top: 50px;
	text-align: left;
	width: 50%;
    transition: all 0.2s ease;
}

.dropdown-button {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    cursor: pointer;
    padding: 8px 16px;
    border: 2px solid rgb(20, 20, 20);
    border-radius: 20px;
	font-size: 18px;
	font-weight: 500;
    gap: 5px;
    background-color: rgb(215, 204, 255);
    transition: all 0.3s ease;
    color: rgb(20, 20, 20);
}

.dropdown-button:hover {
    background-color: rgb(226, 219, 255);
}

#dropdown-content {
    transition: opacity 0.3s ease-out, max-height 0.8s ease-out;
    border: solid 2px rgb(20, 20, 20);
    border-radius: 20px;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    margin: 0;
    margin-top: 5px;
    background-image: linear-gradient(rgb(236, 230, 255) 35%, rgb(241, 241, 241));
    font-size: 16px;
    padding: 10px;
}

.dropdown-gpa {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 0;
}

.dropdown-gpa-grade {
    font-weight: 600;
}

.dropdown-item {
    margin: 20px;
    margin-top: 5px;
}

.dropdown-item-code {
    font-weight: 600;
    color: rgb(0, 35, 37);
}

.dropdown-item-grade {
    font-weight: 600;
    color: rgb(0, 35, 37);
}

.show {
    display: block;
}

@media (max-width: 830px) {
	#portrait-container {
        display: none;
    }
    #text {
		margin-top: 20px;
	  	width: 80%;
        font-size: 18px;
    }
}